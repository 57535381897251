<template>
    <div>
       <a-row :gutter="16">
           <a-col :span="8">
                <v-multi
                    title="催收队列"
                    v-model="member_type[COLLECTION]"
                    :userList="collectionList"
                    :loading="member_loading[COLLECTION]"
                    @confirm="addMember(COLLECTION, $event)"
                    @delete="deleteMember(COLLECTION, $event)"
                />
           </a-col>
           <!-- <a-col :span="8">
                <v-multi
                    title="信审队列"
                    v-model="member_type[APPROVAl]"
                    :userList="approvalList"
                    :loading="member_loading[APPROVAl]"
                    @confirm="addMember(APPROVAl, $event)"
                    @delete="deleteMember(APPROVAl, $event)"
                />
           </a-col>
           <a-col :span="8">
                <v-multi
                    title="提醒队列"
                    v-model="member_type[REMIND]"
                    :userList="remindList"
                    :loading="member_loading[REMIND]"
                    @confirm="addMember(REMIND, $event)"
                    @delete="deleteMember(REMIND, $event)"
                />
           </a-col> -->
       </a-row> 
    </div>
</template>
<script>
    import { getRemindUser } from '@/api/remind';
    import { getExamineUser } from '@/api/approval';
    import { addQueueList, deleteQueueList, getQueueList, getCollectionUser } from '@/api/platformCollection';
    import vMulti from '../operation/modules/MultiSelector'

    export default {
        components: {
            vMulti,
        },
        data () {
            return {
                collectionList: [],
                remindList: [],
                approvalList: [],
                COLLECTION: 2,
                REMIND: 3,
                APPROVAl: 1,
                member_type: {
                    1: [],
                    2: [],
                    3: [],
                },
                member_loading: {
                    1: true,
                    2: true,
                    3: true,
                }
            }
        },
        mounted () {
            getCollectionUser().then(res => {
                if (res.code == 1000) {
                    this.collectionList = (res.data || []);
                } else {
                    this.$message.error('系统繁忙')
                }
            });
            // getRemindUser().then(res => {
            //     if (res.code == 1000) {
            //         this.remindList = (res.data || []);
            //     } else {
            //         this.$message.error('系统繁忙')
            //     }
            // });
            // getExamineUser().then(res => {
            //     if (res.code == 1000) {
            //         this.approvalList = (res.data || []);
            //     } else {
            //         this.$message.error('系统繁忙')
            //     }
            // });
            this.getMember(this.COLLECTION);
            // this.getMember(this.APPROVAl);
            // this.getMember(this.REMIND);
        },
        methods: {
            addMember (type, list) {
                this.member_loading[type] = true;
                const userIds = list.map(item => item.userId);
                addQueueList({userIds, type}).then(res => {
                    if(res.code == 1000) {
                        this.$message.success(this.$t('success'));
                        this.getMember(type);
                    } else {
                        this.$message.error('系统繁忙')
                    }
                })
            },
            deleteMember (type, { userId }) {
                this.member_loading[type] = true;
                deleteQueueList({ userId, type}).then(res => {
                    if (res.code == 1000) {
                        this.$message.success(this.$t('success'))
                        this.getMember(type);
                    } else {
                        this.$message.error('系统繁忙')
                    }
                })
            },
            getMember (type) {
                this.member_loading[type] = true;
                getQueueList({type}).then(res => {
                    if (res.code == 1000) {
                        this.member_type[type] = res.data || [];
                    } else {
                        this.$message.error('系统繁忙')
                    }
                    this.member_loading[type] = false;
                })
            },
        }
    }
</script>
<style lang="less" scoped>
    body {
        background: #fff;
    }
</style>