<template>
  <a-spin :spinning="loading">
    <div :bordered="false">
      <a-form layout="inline"
              @keyup.enter.native="create"
              :labelCol='labelCol'
              :wrapperCol='wrapperCol'
              :rules="rules"
              ref="formRef"
              :model="queryParam">
        <a-row :gutter="24">
          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'workFlowType')" style='width:100%' name='workFlowType' :required='required_true'>
              <a-select v-model="queryParam.workFlowType" :placeholder="$t('select')" style="width: 100%;" @change='handleChange'>
                <a-select-option v-for="item in workFlowTypes" :value="item">
                  {{ $t(I18N_KEY + 'workFlowType_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'questionV1')" style='width:100%' name='questionV1' :required='required_true'>
              <a-select v-model="queryParam.questionV1" :placeholder="$t('select')" style="width: 100%;" @change='handleChange1'>
                <a-select-option v-for="item in questionV1s" :value="item">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'questionV1_' + item) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8" v-if='showNextApprovelUserTrigger' style='margin-top: 30px' name='approvelUser'>
            <a-form-item :label="$t(I18N_KEY + 'nextApproveUser')" style='width:100%' :required='required_true'>
              <a-select v-model="queryParam.approvelUser" :placeholder="$t('select')" style="width: 100%;display: block;">
                <a-select-option v-for="item in nextApproveUsers"  :value="item">
                  {{item}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
<!--        </a-row>-->
<!--        <a-row :gutter="24">-->
          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'custMobile')" style='width:100%' name='custMobile' :required='required_mobile'>
              <a-input style="width: 100%;" v-model="queryParam.custMobile" @change='handleMobileChange'/>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'custEmail')" style='width:100%' name='custEmail' :required='required_email'>
              <a-input style="width: 100%;" v-model="queryParam.custEmail" @change='handleEmailChange'/>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'app')" style='width:100%' name='apps' :required='required_true'>
              <a-select v-model="queryParam.apps" :placeholder="$t('select')" style="width: 100%;" @change='handleChange2'>
                <a-select-option v-for="item in allApps" :value="item.appSsid" >
                  {{ item.appName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'custId')" style='width:100%'>
              <a-input style="width: 100%;" v-model="queryParam.custId" :disabled='disabled'/>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'orderNo')" style='width:100%'>
              <a-input style="width: 100%;" v-model="queryParam.orderNo" :disabled='disabled'/>
            </a-form-item>
          </a-col>
          <!-- 暴力催收显示催收信息 -->
          <!-- 催收日期-催收人员-催收员手机号 -->
          <a-col :md="12" :sm="8" style='margin-top: 30px' v-if='showCollectionTrigger'>
            <a-form-item :label="$t(I18N_KEY + 'collectionDate')" style='width:100%' :required='required_true'>
              <a-date-picker v-model="queryParam.collectionDate" format="YYYY-MM-DD" @change='handleChange4'/>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8" style='margin-top: 30px' v-if='showCollectionTrigger'>
            <a-form-item :label="$t(I18N_KEY + 'collectionUser')" style='width:100%'>
              <a-input style="width: 100%;" v-model="queryParam.collectionUser"/>
            </a-form-item>
          </a-col>
          <a-col :md="12" :sm="8" style='margin-top: 30px' v-if='showCollectionTrigger'>
            <a-form-item :label="$t(I18N_KEY + 'collectionMobile')" style='width:100%'>
              <a-input style="width: 100%;" v-model="queryParam.collectionMobile"/>
            </a-form-item>
          </a-col>

          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'complainChannel')" style='width:100%' name='complainChannel' :required='required_true'>
              <a-select v-model="queryParam.complainChannel" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in complainChannels" :value="item.dictValue">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'complainChannel_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

<!--          <a-col :md="12" :sm="8" style='margin-top: 30px'>-->
<!--            <a-form-item :label='$t(I18N_KEY + "uploadImage")' style='width:100%'>-->
<!--              <a-upload :customRequest='customRequest'-->
<!--                        :remove='remove'-->
<!--                        v-model:file-list="fileList">-->
<!--                <a-button v-if='fileList.length < 4'>{{ $t(I18N_KEY + "upload") }}</a-button>-->
<!--              </a-upload>-->
<!--            </a-form-item>-->
<!--          </a-col>-->

          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label='$t(I18N_KEY + "uploadImage")' style='width:100%'>
              <a-upload :customRequest='customRequest'
                        listType='picture-card'
                        @preview='handlePreview'
                        :beforeUpload='beforeUpload'
                        :remove='remove'
                        v-model:file-list="fileList">
                <div v-if="fileList.length < 8">
<!--                  <a-icon type='plus'>-->
<!--                    <plus-outlined />-->
                    <div class='ant-upload-text'>{{ $t(I18N_KEY + "upload") }}</div>
<!--                  </a-icon>-->
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" :title="previewTitle" :footer="null" @cancel="handleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-form-item>
          </a-col>

          <!--上传视频-->
          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label='$t(I18N_KEY + "uploadVideo")' style='width:100%'>
              <a-upload name='file'
                        :accept="'video/mp4'"
                        listType='picture-card'
                        :customRequest='customRequestVodeo'
                        :beforeUpload='videoBeforeUpload'
                        :remove='removeVideo'
                        @preview='videoPreview'
                        @change='handleChangeVideo'
                        v-model:file-list="videoList">
                <!--                <a-button>{{ $t(I18N_KEY + "upload") }}</a-button>-->
                <div v-if='videoList.length < 8'>
<!--                  <a-icon type='plus'>-->
<!--                    <PlusOutlined />-->
                    <div clas='ant-upload-text'>{{ $t(I18N_KEY + "upload") }}</div>
<!--                  </a-icon>-->
                </div>
                <!--展示-->
                <a-modal :visible='videoPreviewVisible' :footer='null' @cancel='videoPreviewCancel()'>
                  <video width='100%' controls id='video' :src="previewVideo">
                    {{$t(I18N_KEY + "videoPreviewNotice")}}
                  </video>
                </a-modal>
              </a-upload>
            </a-form-item>
          </a-col>

          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'custAppeal')" style='width:100%'>
              <a-select v-model="queryParam.custAppeal" :placeholder="$t('select')" style="width: 100%;">
                <a-select-option v-for="item in custAppeals" :value="item.dictValue">
                  <!--                  {{ item[fieldName] }}-->
                  {{ $t(I18N_KEY + 'custAppeal_' + item.dictValue) }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="12" :sm="8" style='margin-top: 30px'>
            <a-form-item :label="$t(I18N_KEY + 'remark')" style='width:100%'>
              <a-textarea col='3' :placeholder="$t('enter') + ' ' + $t(I18N_KEY + 'remark')"  v-model="queryParam.remark"/>
            </a-form-item>
          </a-col>


          <a-col :md="24" :sm="8" style='margin-top: 30px;text-align: center'>
            <span style="float: right;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button type="primary" @click="create" icon="center">{{ $t(I18N_KEY + 'create') }}</a-button>
<!--              <a-button type="primary" @click="searchReset" icon="reload" style="margin-left: 8px">{{ $t('reset') }}</a-button>-->
            </span>
          </a-col>
        </a-row>

      </a-form>
    </div>
  </a-spin>
</template>
<script>
import { filterObj } from '@/utils/util';
import {
  detailBaseData,
  getquestionV1ByWorkFlowType,
  nextApprovelData,
  collectionUser,
  custUser,
  createWorkOrder,
  uploadAndGet
} from '@/webpublicapi/custServiceWorkOrder'
import moment from 'moment';
import { upload } from '@/webpublicapi/teleMarket'
// import PlusOutlined from '@ant-design/icons-vue';
const I18N_KEY = 'custServiceWorkOrder.';
export default {
  name: 'WorkOrderCreate',
  // components: {
  //   PlusOutlined,
  // },
  data () {
    return {
      I18N_KEY,
      visible: false,
      orderId: undefined,
      workFlowTypes: [],
      // fieldKey: 'dictCnText',
      // types: [],// 工单类型
      // statuses: [],// 工单状态
      // operateTypes: [],// 工作流操作类型
      // firstLevelQuestions: [],// 一级问题
      // secondLevelQuestions: [],// 二级问题
      questionV1s: [],// 问题标签V1
      custAppeals: [],// 客户诉求
      complainChannels: [],// 客诉渠道
      // canPasses: [],// 是否转让
      // apps: [],// 包列表
      allApps: [],// 包列表
      // days: ['all','yesterday','today','last7days','last1month','other'],
      // form: {
      //   pageNum: 1,
      //   pageSize: 10,
      // },
      // total: 0,
      // dataSource: [],
      // 查询条件
      required_true: true,
      required_mobile: true,
      required_email: true,
      queryParam: {
        "workFlowType": '',
        "custMobile": '',
        "custEmail": '',
        "complainChannel": '',
        // "custQueryData": '',
        "questionV1": '',
        "custAppeal": '',
        "apps": '',
        "attachmentCode": '',
        "videoCode": '',
        "collectionUser": '',
        "collectionMobile": '',
        "collectionDate": '',
        "remark": '',
        "approvelUser": '',
        "orderNo": '',
        "custId": undefined,
      },
      nextApproveUsers: [],
      showNextApprovelUserTrigger: false,
      showCollectionTrigger: false,
      fileList: [],
      imgCount: 0,
      videoCount: 0,
      imageLoading:[],
      videoLoading:[],
      disabled: false,
      loading: false,
      // 表头
      // isorter: {
      //   column: 'createTime',
      //   order: 'desc'
      // },
      // labelCol: {
      //   xs: { span: 8 },
      //   sm: { span: 5 }
      // },
      // wrapperCol: {
      //   xs: { span: 16 },
      //   sm: { span: 19 }
      // },
      labelCol:{ span: 8 },
      wrapperCol:{ span: 12 },
      rules: {
        workFlowType: [{required: true, message: 'Please select workFlowType'}],
        questionV1: [{required: true, message: 'Please select questionV1'}],
        // approvelUser: [{validator: this.validateApprovelUser}],
        // custMobile: [{validator: this.validateCustMobile}],
        // custEmail: [{validator: this.validateCustEmail}],
        apps: [{required: true, message: 'Please select apps'}],
        complainChannel: [{required: true, message: 'Please select apps'}],
      },
      previewImage: '',
      previewVideo: '',
      previewVisible: false,
      previewTitle: '',
      videoPreviewVisible: false,
      videoList: [],
      imageSizeLimit: 10,
      videoSizeLimit: 30,
    }
  },
  created () {
    this.loadBaseData();
  },
  methods: {
    loadBaseData() {
      detailBaseData({}).then((res) => {
        if (res.code === 1000) {
          // this.types = res.data.types
          // this.canPasses = res.data.canPasses
          this.custAppeals = res.data.custAppeals
          // this.statuses = res.data.statuses
          // this.operateTypes = res.data.operateTypes
          // this.apps = res.data.apps
          // this.allApps = res.data.allApps
          var newApps = [{"appName": this.$t(I18N_KEY + 'unknow'), "appSsid": "unknown"}];
          res.data.allApps.map(app => newApps.push(app))
          this.allApps = newApps
          this.workFlowTypes = res.data.workFlowTypes
          this.complainChannels = res.data.complainChannels
          this.videoSizeLimit = res.data.videoSizeLimit || this.videoSizeLimit
          this.imageSizeLimit = res.data.imageSizeLimit || this.imageSizeLimit
          // this.firstLevelQuestions = res.data.firstLevelQuestions
          // this.questionV1s = res.data.questionV1s
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    transformTime(time) {
      return moment(time).format('YYYY-MM-DD')
    },
    transformStringToLow(str) {
      return str && str.toLocaleLowerCase();
    },
    getQueryParams() {
      var attacmentCodes = '';
      if(this.fileList.length > 0) {
        for(var item in this.fileList){
          var file = this.fileList[item];
          if(attacmentCodes === '') {
            attacmentCodes = file.attCode
          } else {
            attacmentCodes = attacmentCodes + ',' + file.attCode
          }
        }
        this.queryParam.attachmentCode = attacmentCodes
      }
      var videoCodes = '';
      if(this.videoList.length > 0) {
        for(var item in this.videoList){
          var file = this.videoList[item];
          if(videoCodes === '') {
            videoCodes = file.attCode
          } else {
            videoCodes = videoCodes + ',' + file.attCode
          }
        }
        this.queryParam.videoCode = videoCodes
      }
      const data = {
        ...this.queryParam,
        collectionDate: this.queryParam.collectionDate && this.transformTime(this.queryParam.collectionDate)
      }
      if(this.queryParam.apps === 'unknown') {
        data["apps"] = ''
      }
      var param = Object.assign({}, data)
      return filterObj(param)
    },
    // 重置字典类型搜索框的内容
    searchReset() {
      var that = this
      that.queryParam.custMobile = ''
      that.queryParam.custEmail = ''
      that.queryParam.apps = ''
      that.queryParam.approvelUser = ''
      that.queryParam.questionV1 = ''
      that.queryParam.custAppeal = ''
    },
    test(){
      this.$router.push({
        path: '/webpublic/custService/Dict'
      })
    },
    handleChange(workFlowType){
      getquestionV1ByWorkFlowType(workFlowType).then(res => {
        if(res.code === 1000){
          this.questionV1s = res.data || [];
          this.queryParam.questionV1 = '';
          this.showNextApprovelUserTrigger = false;
          // this.$message.success('success');
        } else {
          this.$message.error(res.msg);
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    handleChange1(questionV1) {
      if(questionV1 === '4') {
        this.showCollectionTrigger = true;
      } else {
        this.showCollectionTrigger = false;
        this.queryParam.collectionDate = ''
        this.queryParam.collectionUser = ''
        this.queryParam.collectionMobile = ''
      }
      var param = {
        "workFlowType": this.queryParam.workFlowType,
        "questionV1": questionV1
      }
      nextApprovelData(param).then(res => {
        if (res.code === 1000) {
          this.showNextApprovelUserTrigger = res.data.hasRole || false;
          // this.nextApproveRole = res.data.nextApproveRole || '';
          this.nextApproveUsers = res.data.nextApproveUsers || [];
          this.queryParam.approvelUser = res.data.hostoryUser || '';
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    handleChange2(appSsid) {
      if(this.queryParam.custMobile == '' && this.queryParam.custEmail == '') {
        return;
      }
      var param = {
        "custMobile": this.queryParam.custMobile,
        "custEmail": this.queryParam.custEmail,
        "apps": appSsid==='unknow'?'':appSsid,
      }
      custUser(param).then(res => {
        if (res.code === 1000) {
          this.queryParam.custId = res.data.custId
          this.queryParam.orderNo = res.data.orderNo
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    handleChange4(date, dateString){
      var custId = this.queryParam.custId
      if(this.checkNull(custId)) {
        return;
      }
      var param = {
        "custId": custId,
        "collectionDate": dateString,
      }
      collectionUser(param).then(res => {
        if (res.code === 1000) {
          this.queryParam.collectionUser = res.data.userName
          this.queryParam.collectionMobile = res.data.mobile
        } else {
          this.$message.error(res.msg)
        }
      }).catch(e => {
        this.$message.error(e.message);
      })
    },
    create() {
      // this.$refs.formRef.validateFields().then(() => {})
      this.loading = true
      var notice = this.checkNotice();
      if(!this.checkNull(notice)) {
        this.$message.error(this.$t(I18N_KEY + notice))
        this.loading = false
        return;
      }
      var params = this.getQueryParams()
      createWorkOrder(Object.assign({}, params)).then((res) => {
        if (res.code === 1000) {
          this.$message.info("success")
          this.$emit('closeLoad')
        } else {
          this.$message.error(res.msg)
        }
        this.loading = false
      }).catch(e => {
        this.loading = false;
        this.$message.error(e.message);
      })
    },
    checkNotice() {
      for(var item in this.imageLoading) {
        if("loading" === this.imageLoading[item]) {
          // this.$message.error(this.$t(I18N_KEY + 'imageLoadingNotice'))
          return 'imageLoadingNotice';
        }
      }
      for(var item in this.videoLoading) {
        if("loading" === this.videoLoading[item]) {
          // this.$message.error(this.$t(I18N_KEY + 'imageLoadingNotice'))
          return 'videoLoadingNotice';
        }
      }
      var workFlowType = this.queryParam.workFlowType;
      var questionV1 = this.queryParam.questionV1;
      var custMobile = this.queryParam.custMobile;
      var custEmail = this.queryParam.custEmail;
      var approvelUser = this.queryParam.approvelUser
      var apps = this.queryParam.apps
      var collectionDate = this.queryParam.collectionDate
      var complainChannel = this.queryParam.complainChannel
      if(this.checkNull(workFlowType)) {
        return 'workFlowTypeNotice'
      }
      if(this.checkNull(questionV1)) {
        return 'questionV1Notice'
      }
      if(this.showNextApprovelUserTrigger && this.checkNull(approvelUser)) {
        return 'approvelUserNotice'
      }
      if(this.checkNull(custMobile) && this.checkNull(custEmail)) {
        return 'custMobileAndCustEmailNotice'
      }
      if(this.checkNull(apps)) {
        return 'appsNotice'
      }
      if(this.showCollectionTrigger && this.checkNull(collectionDate)) {
        return 'collectionDateNotice'
      }
      if(this.checkNull(complainChannel)) {
        return 'complainChannelNotice'
      }
    },
    checkNull(param) {
      if(param == undefined || param == null || param == '') {
        return true;
      }
      return false;
    },
    customRequest(file){
      const form = new FormData();
      var theCount = this.imgCount;
      this.imgCount = this.imgCount + 1;
      this.imageLoading[Number(theCount)] = 'loading'
      form.append('file', file.file)
      uploadAndGet(form).then(res => {
        if (res.code === 1000) {
          file.onSuccess(res, file.file)
          file.status = 'done'
          this.imageLoading[Number(theCount)] = 'done';
          this.$message.info("success")
          file['attCode'] = res.data.attachmentCode
          file['url'] = res.data.attachmentUrl
          this.fileList.push(file);
          console.log(file)
        } else {
          file.onError()
          file.status = 'error'
          this.imageLoading[Number(theCount)] = 'done';
          this.$message.error(res.msg)
        }
      }).catch(e => {
        file.onError()
        file.status = 'error'
        this.imageLoading[Number(theCount)] = 'done';
        this.$message.error(e.message);
      })
    },
    remove(file) {
      // console.log(file)
      if(file.status == 'error') {
        return true;
      }
      var newList = [];
      for(var item in this.fileList){
        var theFile = this.fileList[item];
        if(theFile.attCode === file.response.data.attachmentCode) {
          continue;
        }
        newList.push(theFile);
      }
      this.fileList = newList;
      // console.log(newList)
      return true;
    },
    // validateApprovelUser(rule, value, callback){
    //   if(this.showNextApprovelUserTrigger && (value == undefined || value == null || value == '')) {
    //     callback(this.$t(I18N_KEY + 'approvelUserNotice'))
    //   }
    //   callback()
    // },
    // validateCustMobile(rule, value, callback) {
    //   var custEmail = this.queryParam.custEmail
    //   if((custEmail == undefined || custEmail == null || custEmail == '') && (value == undefined || value == null || value == '')) {
    //     callback(this.$t(I18N_KEY + 'custMobileAndCustEmailNotice'))
    //   }
    //   callback()
    // },
    // validateCustEmail(rule, value, callback) {
    //   var custMobile = this.queryParam.custMobile
    //   if((custMobile == undefined || custMobile == null || custMobile == '') && (value == undefined || value == null || value == '')) {
    //     callback(this.$t(I18N_KEY + 'custMobileAndCustEmailNotice'))
    //   }
    //   callback()
    // },
    handlePreview(file) {
      console.log(file)
      if (!file.url && !file.preview) {
        file.preview = file.thumbUrl
        // file.preview = file.response.data.attachmentUrl
      }
      this.previewImage = file.url || file.preview;
      // this.previewImage = file.url;
      this.previewVisible = true;
      this.previewTitle = file.name || file.url.substring(file.url.lastIndexOf('/') + 1);
    },
    handleCancel(){
      this.previewVisible = false;
      this.previewTitle = '';
    },
    // beforeUpload(file){
    //   console.log(file)
    //   const isLt10M = file.size < this.imageSizeLimit * 1024 * 1024
    //   const isMatchType = file.type === 'image/jpeg'
    //     || file.type === 'image/png'
    //     || file.type === 'image/jpg'
    //     || file.type === 'image/gif'
    //   if(!isLt10M) {
    //     this.$message.error(this.$t(I18N_KEY + 'imageSizeNotice_pre') + this.imageSizeLimit + this.$t(I18N_KEY + 'imageSizeNotice_suf'))
    //   }
    //   if(!isMatchType) {
    //     this.$message.error(this.$t(I18N_KEY + 'imageTypeNotice'))
    //   }
    //
    //   return isLt10M && isMatchType;
    // },
    beforeUpload(file) {
      const _this = this
      return new Promise(function(resolve, reject) {
        if(_this.checkImageType(file) && _this.checkImageSize(file)) {
          resolve()
        } else {
          reject()
        }
      })
    },
    checkImageType(file){
      if(file.type === 'image/jpeg'
        || file.type === 'image/png'
        || file.type === 'image/jpg'
        || file.type === 'image/gif') {
        return true;
      }
      this.$message.error(this.$t(I18N_KEY + 'imageTypeNotice'))
      return false;
    },
    checkImageSize(file){
      if (file.size <= this.imageSizeLimit * 1024 * 1024) {
        return true;
      }
      this.$message.error(this.$t(I18N_KEY + 'imageSizeNotice_pre') + this.imageSizeLimit + this.$t(I18N_KEY + 'imageSizeNotice_suf'))
      return false;
    },

    // 视频操作
    customRequestVodeo(file){
      const form = new FormData();
      var theCount = this.videoCount;
      this.videoCount = this.videoCount + 1;
      this.videoLoading[Number(theCount)] = 'loading'
      form.append('file', file.file)
      uploadAndGet(form).then(res => {
        if (res.code === 1000) {
          file.onSuccess(res, file.file)
          file.status = 'done'
          this.videoLoading[Number(theCount)] = 'done';
          this.$message.info("success")
          file['attCode'] = res.data.attachmentCode
          file['url'] = res.data.attachmentUrl
          this.videoList.push(file);
          console.log(file)
          // console.log(file)
        } else {
          file.onError()
          file.status = 'error'
          this.videoLoading[Number(theCount)] = 'done';
          this.$message.error(res.msg)
        }
      }).catch(e => {
        file.onError()
        file.status = 'error'
        this.videoLoading[Number(theCount)] = 'done';
        this.$message.error(e.message);
      })
    },
    removeVideo(file){
      // console.log(file)
      if(file.status == 'error') {
        return true;
      }
      var newList = [];
      for(var item in this.videoList){
        var theFile = this.videoList[item];
        if(theFile.attCode === file.response.data.attachmentCode) {
          continue;
        }
        newList.push(theFile);
      }
      this.videoList = newList;
      // console.log(newList)
      return true;
    },
    videoPreviewCancel() {
      this.videoPreviewVisible = false;
      this.previewVideo = ''
    },
    videoPreview(file) {
      console.log(file)
      if (!file.url && !file.preview) {
        file.preview = file.response.data.attachmentUrl
      }
      this.videoPreviewVisible = true;
      this.previewVideo = file.url || file.preview;
      // this.$nextTick(()=>{
      //   const video = document.getElementById('video')
      //   video.src = file.url || file.preview;
      // })
    },
    // videoBeforeUpload(file){
    //   console.log(file)
    //   const isLt10M = file.size < this.videoSizeLimit * 1024 * 1024
    //   const isMatchType = file.type === 'video/mp4' || file.type === 'video/ogg' || file.type === 'video/flv' || file.type === 'video/mov' || file.type === 'video/wmv'
    //   if(!isLt10M) {
    //     this.$message.error(this.$t(I18N_KEY + 'videoSizeNotice_pre') + this.videoSizeLimit + this.$t(I18N_KEY + 'videoSizeNotice_suf'))
    //   }
    //   if(!isMatchType) {
    //     this.$message.error(this.$t(I18N_KEY + 'videoTypeNotice'))
    //   }
    //
    //   return isLt10M && isMatchType;
    // },
    videoBeforeUpload(file) {
      const _this = this
      return new Promise(function(resolve, reject) {
        if(_this.checkVideoType(file) && _this.checkVideoSize(file)) {
          resolve()
        } else {
          reject()
        }
      })
    },
    checkVideoType(file){
      if(file.type === 'video/mp4'
        || file.type === 'video/ogg'
        || file.type === 'video/flv'
        || file.type === 'video/mov'
        || file.type === 'video/wmv') {
        return true;
      }
      this.$message.error(this.$t(I18N_KEY + 'videoTypeNotice'))
      return false;
    },
    checkVideoSize(file){
      if (file.size <= this.videoSizeLimit * 1024 * 1024) {
        return true;
      }
      this.$message.error(this.$t(I18N_KEY + 'videoSizeNotice_pre') + this.videoSizeLimit + this.$t(I18N_KEY + 'videoSizeNotice_suf'))
      return false;
    },
    handleChangeVideo(fileList){
      setTimeout(() => {
        const classActions = document.getElementsByClassName('ant-upload-list-item-actions')
        classActions.forEach(ca => {
          ca.children[0].style.opacity = '1'
          ca.children[0].style.pointerEvents = 'initial'
        })
        // console.log(classActions)
      }, 30)
    },
    handleMobileChange(mobile){
      if(!this.checkNull(this.queryParam.custMobile)) {
        this.required_mobile = true
        if(!this.checkNull(this.queryParam.custEmail)) {
          this.required_email = true
        } else {
          this.required_email = false
        }
      } else {
        this.required_email = true
        if(!this.checkNull(this.queryParam.custEmail)) {
          this.required_mobile = false
        } else {
          this.required_mobile = true
        }
      }
    },
    handleEmailChange(email){
      if(!this.checkNull(this.queryParam.custEmail)) {
        this.required_email = true
        if(!this.checkNull(this.queryParam.custMobile)) {
          this.required_mobile = true
        } else {
          this.required_mobile = false
        }
      } else {
        this.required_mobile = true
        if(!this.checkNull(this.queryParam.custMobile)) {
          this.required_email = false
        } else {
          this.required_email = true
        }
      }
    },
  },
  watch: {
    videoPreviewVisible: function(val) {
      if(!val) {
        const video = document.getElementById("video")
        video.pause()
      }
    },
  },
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/common.less";
</style>